.snc-uc #site-name, .ssc-uc #site-slogan, .ptc-uc #page-title, .ntc-uc .node-title, .ctc-uc .comment-title, .btc-uc .block-title {
  text-transform: uppercase;
}

.snc-lc #site-name, .ssc-lc #site-slogan, .ptc-lc #page-title, .ntc-lc .node-title, .ctc-lc .comment-title, .btc-lc .block-title {
  text-transform: lowercase;
}

.snc-c #site-name, .ssc-c #site-slogan, .ptc-c #page-title, .ntc-c .node-title, .ctc-c .comment-title, .btc-c .block-title {
  text-transform: capitalize;
}

.snc-sc #site-name, .ssc-sc #site-slogan, .ptc-sc #page-title, .ntc-sc .node-title, .ctc-sc .comment-title, .btc-sc .block-title {
  text-transform: none;
  font-variant: small-caps;
}

.snw-n #site-name, .ssw-n #site-slogan, .ptw-n #page-title, .ntw-n .node-title, .ctw-n .comment-title, .btw-n .block-title {
  font-weight: 400;
}

.snw-b #site-name, .ssw-b #site-slogan, .ptw-b #page-title, .ntw-b .node-title, .ctw-b .comment-title, .btw-b .block-title {
  font-weight: 700;
}

.sna-l #site-name, .ssa-l #site-slogan, .pta-l #page-title, .nta-l .node-title, .cta-l .comment-title, .bta-l .block-title {
  text-align: left;
}

.sna-r #site-name, .ssa-r #site-slogan, .pta-r #page-title, .nta-r .node-title, .cta-r .comment-title, .bta-r .block-title {
  text-align: right;
}

.sna-c #site-name, .ssa-c #site-slogan, .pta-c #page-title, .nta-c .node-title, .cta-c .comment-title, .bta-c .block-title {
  text-align: center;
}

.sns-l #site-name, .sss-l #site-slogan, .pts-l #page-title, .nts-l .node-title, .cts-l .comment-title, .bts-l .block-title {
  text-shadow: 0 1px 1px #0000004d;
}

.sns-d #site-name, .sss-d #site-slogan, .pts-d #page-title, .nts-d .node-title, .cts-d .comment-title, .bts-d .block-title {
  text-shadow: 0 1px 1px #0009;
}

.sns-w #site-name, .sss-w #site-slogan, .pts-w #page-title, .nts-w .node-title, .cts-w .comment-title, .bts-w .block-title {
  text-shadow: 0 1px 1px #fffc;
}

.container {
  margin: 0 auto;
}

.content-inner {
  min-height: 1px;
}

.lt-ie7 .content-inner {
  height: 1px;
}

#content-column, .content-column {
  width: 100%;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.clearfix {
  zoom: 1;
}

.one-column > .region, div.at-panel .region-conditional-stack {
  float: none;
  clear: both;
  width: 100%;
  display: block;
}

.lt-ie8 .at-panel {
  overflow: hidden;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden] {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1.5;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility\9 ;
  margin: 0;
  padding: 0;
}

button, input, select, textarea {
  font-family: sans-serif;
}

a:focus {
  outline: thin dotted;
}

a:hover, a:active {
  outline: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

h2 {
  margin: .83em 0;
  font-size: 1.5em;
}

h3 {
  margin: 1em 0;
  font-size: 1.17em;
}

h4 {
  margin: 1.33em 0;
  font-size: 1em;
}

h5 {
  margin: 1.67em 0;
  font-size: .83em;
}

h6 {
  margin: 2.33em 0;
  font-size: .75em;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: 700;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

p, pre {
  margin: 0 0 1.5em;
}

pre, code, kbd, samp {
  _font-family: "courier new", monospace;
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 75%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

dl, menu, ol, ul {
  margin: 1em 0;
}

dd {
  margin: 0 0 0 40px;
}

menu, ol, ul {
  padding: 0 0 0 40px;
}

nav ul, nav ol {
  list-style: none;
}

img {
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

figure, form {
  margin: 0;
}

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  white-space: normal;
  border: 0;
  padding: 0;
}

button, input, select, textarea {
  vertical-align: baseline;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled], input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;
  font: 100%;
  border: 1px solid;
}

#main-content, .block-inner, .pane-inner, .menu-wrapper, .branding-elements, .breadcrumb-wrapper, .attribution, .at-panel .rounded-corner, .block-panels-mini > .block-title, div.messages {
  margin-left: 10px;
  margin-right: 10px;
}

#content .panel-display, #content .panel-flexible {
  margin-left: -10px;
  margin-right: -10px;
}

img {
  height: auto;
  -ms-interpolation-mode: bicubic;
}

img, embed, object, video {
  max-width: 100%;
}

.lt-ie9 img, .lt-ie9 object, .lt-ie9 embed, .lt-ie9 video {
  max-width: none;
}

#map img, .gmap img, .view-gmap img, .openlayers-map img, #getlocations_map_canvas img, #locationmap_map img, .geofieldMap img, .views_horizontal_slider img {
  max-width: none !important;
}

header[role="banner"], .content-inner, .nav, .region-sidebar-first, .region-sidebar-second, .region-secondary-content, .region-tertiary-content, .region-footer {
  word-wrap: break-word;
  overflow: visible;
}

.ir {
  text-indent: 100%;
  white-space: nowrap;
  font: 0 / 0 a;
  text-shadow: none;
  color: #0000;
  background-color: #0000;
  border: 0;
  overflow: hidden;
  display: block !important;
}

.element-invisible {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.element-invisible.element-focusable:active, .element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  position: static;
  overflow: visible;
}

.offscreen {
  width: 1px;
  height: 1px;
  outline: 0;
  position: absolute;
  top: -99999em;
  overflow: hidden;
}

.element-hidden {
  display: none;
}

html {
  background: #fff;
}

body {
  font-family: Trebuchet MS, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: 100%;
}

h2 {
  border-bottom: 1px dotted #c00;
}

h3 {
  color: #c00;
}

h6 + ul, h5 + ul, h4 + ul, h3 + ul, h2 + ul, h2 + ul, p + ul {
  margin-top: 0;
}

ul ~ p, ul ~ h6, ul ~ h5, ul ~ h4, ul ~ h3, ul ~ h2, p ~ h6, p ~ h5, p ~ h4, p ~ h3, p ~ h2 {
  margin-bottom: 0;
}

pre, code, tt, samp, kbd, var {
  font-family: Consolas, Monaco, Courier New, Courier, monospace, sans-serif;
}

#header-wrapper, #footer-wrapper {
  background: #ffc0cb80;
}

#logo {
  padding: 10px 0;
}

#logo img {
  vertical-align: bottom;
}

#site-name {
  margin: 0;
}

#site-name a:link, #site-name a:visited {
  text-decoration: none;
}

#site-name a:hover, #site-name a:focus {
  text-decoration: underline;
}

#site-slogan, #page-title {
  margin: 0;
}

#aggregator .feed-source .feed-icon {
  float: none;
  margin-right: 10px;
  display: inline;
}

.feed-details dt, .feed-details dd {
  margin: 0;
  display: inline;
}

ul.links {
  margin: 0;
  padding: 0;
}

ul.links.inline {
  display: block;
}

ul.links li {
  padding: 0 10px 0 0;
  list-style: none;
  display: inline;
}

.search-results {
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

.nav {
  clear: both;
  margin: 10px 0;
}

.nav ul, .nav ul.menu {
  margin: 0;
  padding: 0;
}

.nav li, .nav ul.menu li {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

.nav li a, .nav ul.menu li a {
  white-space: nowrap;
  padding: 0 10px;
  display: block;
}

.nav .block, ul.sf-menu {
  margin-bottom: 0;
}

ul.sf-menu a {
  height: 2.5em;
  border-top: 0;
  border-left: 0;
  padding: 0 10px;
  line-height: 2.5em;
  text-decoration: none;
}

ul.sf-menu li:hover, ul.sf-menu li.sfHover, ul.sf-menu a:focus, ul.sf-menu a:hover, ul.sf-menu a:active {
  outline: 0;
}

.block-superfish ul, .block-superfish li {
  margin: 0 !important;
  padding: 0 !important;
}

.sf-vertical, .sf-vertical li {
  width: 100%;
}

.sf-vertical li:hover ul, .sf-vertical li.sfHover ul {
  margin: 0;
  padding: 0;
  top: 0;
  left: 100%;
}

.sf-vertical li a {
  padding: 0 10px;
}

.sf-navbar {
  padding-bottom: 0 !important;
}

.sf-menu.sf-style-default a {
  padding: 0 10px;
}

ul.menu, ul.menu ul {
  padding-left: 15px;
}

ul.menu li {
  margin: 0;
}

.block .menu li.content {
  padding: 0;
}

.book-navigation .page-up {
  min-width: 2em;
  white-space: nowrap;
}

.book-navigation .menu {
  margin-left: 0;
}

#breadcrumb {
  margin: 10px 0;
}

#breadcrumb .breadcrumb-label {
  padding-right: 10px;
  font-size: 1em;
  display: inline;
}

#breadcrumb .breadcrumb-label:after {
  content: ":";
}

#breadcrumb ol {
  margin: 0;
  padding: 0;
}

#breadcrumb .with-breadcrumb-label ol {
  display: inline;
}

#breadcrumb li {
  list-style: none;
  display: inline;
}

ul.pager {
  clear: both;
  text-align: center;
  margin: 0;
}

.item-list ul.pager li {
  margin: 0;
}

ul.pager li {
  background-image: none;
  padding: .5em;
  list-style-type: none;
  display: inline;
}

ul.pager li.pager-current {
  font-weight: 700;
}

.block ul.pager li {
  margin: 0;
}

#skip-link {
  width: 12em;
  z-index: 50;
  margin-top: 0;
  margin-left: -6.5em;
  padding: 0 .5em;
  position: absolute;
  left: 50%;
}

#skip-link a, #skip-link a:link, #skip-link a:visited {
  color: #fff;
  text-align: center;
  background: #0009;
  padding: 0;
  line-height: 2;
  text-decoration: none;
  display: block;
}

#skip-link a:hover, #skip-link a:focus, #skip-link a:active {
  outline: 0;
}

#tasks {
  margin-bottom: 15px;
}

ul.primary {
  border-bottom-color: #ccc;
  margin: 20px 0;
  padding: 0 0 0 5px;
}

ul.primary li {
  float: left;
  margin: 0 1px -1px;
  display: block;
}

ul.primary li a {
  float: left;
  height: 1.5em;
  background-color: #f5f5f5;
  border-color: #ccc;
  margin-right: 1px;
  padding: 0 10px;
  line-height: 1.5em;
  display: block;
}

ul.primary li a:hover, ul.primary li a:focus {
  background-color: #eee;
  border-color: #ccc;
}

ul.primary li.active a, ul.primary li.active a:hover, ul.primary li.active a:focus {
  background-color: #fff;
  border-bottom-color: #fff;
}

ul.secondary {
  border-bottom: 1px solid #ccc;
  margin: 1em 0 0;
  padding: 0 .3em 1em;
}

ul.secondary li {
  border-right: 0;
  padding: 0 10px 0 0;
  list-style: none;
}

ul.secondary li a:hover, ul.secondary li a.active {
  border-bottom: none;
  text-decoration: underline;
}

ul.action-links {
  margin: 20px 0 0;
  list-style: none;
}

.field-label {
  font-family: inherit;
  font-size: 1em;
  font-weight: 700;
  line-height: inherit;
  margin-bottom: 0;
}

.field-type-taxonomy-term-reference {
  margin-bottom: 1.5em;
}

.field-type-taxonomy-term-reference.field-label-inline .field-items {
  margin: 0;
  padding: 0;
}

.field-type-taxonomy-term-reference.field-label-inline .field-item {
  padding: 0 10px 0 0;
  list-style: none;
  display: inline;
}

.ia-l .field-type-image figure, .iat-l .field-type-image figure {
  margin: 5px 20px 15px 0;
}

.ia-c .field-type-image figure, .iat-c .field-type-image figure {
  margin: 5px auto 15px;
}

.ia-r .field-type-image figure, .iat-r .field-type-image figure {
  margin: 5px 0 15px 20px;
}

.block {
  margin-bottom: 20px;
}

.block-title {
  margin: 0;
}

.block-content ul, .block-content ol {
  padding: 0 0 0 15px;
}

.block-content li {
  margin: 0;
  padding: 0;
}

.node {
  margin-bottom: 20px;
}

.node .node-title {
  margin: 0;
}

#comments {
  margin: 1.5em 0;
}

#comments h2.comment-title, #comments h2.comment-form {
  margin: 0;
}

.comment {
  margin-bottom: 20px;
}

.comment-title {
  margin: 0;
}

.new {
  color: #c00;
}

.indented {
  margin-left: 40px;
}

.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 1px solid #c00;
}

.form-item label {
  font-weight: 700;
}

.marker, .form-required {
  color: #c00;
}

.form-item .description {
  font-size: .85em;
}

.container-inline div, .container-inline label {
  display: inline;
}

fieldset {
  border: 1px solid #ccc;
}

a.button {
  appearance: button;
}

.password-parent, .confirm-parent {
  margin: 0;
}

table {
  width: 100%;
  margin: 10px 0;
  padding: 0;
}

table.sticky-header {
  z-index: 10;
}

table, thead, tbody, tr, th, td {
  border-color: #ccc;
}

table, td, th {
  vertical-align: middle;
}

caption, th, td {
  text-align: left;
}

thead tr {
  background-color: #e5e5e5;
  font-weight: 700;
}

td, th {
  border-bottom: 0;
  margin: 0;
  padding: 5px 7px;
}

tbody tr {
  border-top: 1px solid #ccc;
}

tr.odd {
  background: #fff;
}

tr.info, tr.even, tr:nth-child(2n+2) {
  background-color: #f5f5f5;
  border-bottom: 0;
}

tr.odd td.active {
  background-color: #eee;
}

tr.even td.active {
  background-color: #ebebeb;
}

#forum td .created, #forum td .posts, #forum td .topics, #forum td .last-reply, #forum td .replies, #forum td .pager {
  white-space: normal;
}

div.messages {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.messages ul {
  margin-top: 0;
  margin-bottom: 0;
}

.node-unpublished p.unpublished, .comment-unpublished p.unpublished {
  color: pink;
  color: #efaaaa66;
  height: 0;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  font-family: Impact, Arial Narrow, Helvetica, sans-serif;
  font-size: 75px;
  font-weight: bold;
  line-height: 1.2;
  overflow: visible;
}

.lt-ie8 .node-unpublished > *, .lt-ie8 .comment-unpublished > * {
  position: relative;
}

.maintenance-page .container {
  padding: 40px 0;
}

.maintenance-page #site-name, .maintenance-page #page-title {
  margin: 0;
}

.db-offline .container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 0;
}

.db-offline div.messages {
  margin: 20px 0 0;
}

.db-offline #content {
  padding: 20px 0;
}

#admin-menu {
  margin: 0;
  padding: 0;
}

.dev-query {
  background: #eee;
  padding: 30px;
}

#styleguide-header {
  padding: 0 10px;
}

#styleguide-header .item-list {
  min-height: 260px;
  width: auto;
  margin: 0 20px 20px 0;
  font-family: inherit;
}

/*# sourceMappingURL=index.ab903bd0.css.map */
